import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';

// import mapImg from 'common/src/assets/image/hosting/Pages/map.svg';
import mapImg from 'common/src/assets/image/hosting/Pages/RAP AI Map.png';

import ContactFromWrapper from './contact.style';
// import './contactCard.css';

const ContactSection = ({
  sectionWrapper,
  row,
  row2,
  col,
  contactMap,
  addressForm,
  secTitleWrapper,
  addrHead,
  addrText,
  button,
  note,
}) => {
  return (
    <Box {...sectionWrapper}>
      <ContactFromWrapper>
        <Box {...row}>
          <Box {...addressForm}>
            <Box {...row2}>
              <Box {...col} className="addressBlock">
                <Heading {...addrHead} content="Dallas" />
                <Text
                  {...addrText}
                  dangerouslySetInnerHTML={{
                    __html: '8600 Freeport Pkwy Ste 220, Irving TX 75063',
                  }}
                />
              </Box>
              <Box {...col} className="addressBlock">
                <Heading {...addrHead} content="Chennai" />
                <Text
                  {...addrText}
                  dangerouslySetInnerHTML={{
                    __html:
                      '2nd Floor, Greeta Tech Park Phase-1, North Wing #96, VSI Functional, Industrial Estate, Perungudi, Chennai, Tamil Nadu 600096',
                  }}
                />
              </Box>
              <Box {...col} className="addressBlock">
                <Heading {...addrHead} content="Pune" />
                <Text
                  {...addrText}
                  dangerouslySetInnerHTML={{
                    __html:
                      '401/402, Pride Icon, MH SH 27, Thite Nagar, Kharadi, Pune, Maharashtra 411014',
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box {...contactMap}>
            <Fade left cascade>
              <div className="contact_map_container">
                <img src={mapImg} alt="Contact Map" />
              </div>
            </Fade>
          </Box>
        </Box>
      </ContactFromWrapper>
    </Box>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  row2: PropTypes.object,
  col: PropTypes.object,
  contactMap: PropTypes.object,
  addressForm: PropTypes.object,
  addrHead: PropTypes.object,
  addrText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: '60px',
    pb: ['35px', '35px', '50px', '60px'],
    bg: '#f9fbfd',
  },
  secTitleWrapper: {
    mb: ['45px', '50px', '60px'],
  },
  addrHead: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    fontWeight: 'bold ',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mt: 0,
    mb: 0,
    pt: '0',
    pb: '10px',
    textAlign: 'center',
  },
  addrText: {
    fontSize: ['14px', '16px', '16px', '16px', '16px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mt: '0',
    mb: '0',
    textAlign: 'center',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    className: 'contact_box',
    flexDirection: 'column-reverse',
    flexWrap: 'wrap',
  },
  row2: {
    flexBox: true,
    justifyContent: 'space-around',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  col: {
    width: [1, 1, '30%', '30%', '30%'],
    margin: '25px 0',
  },
  addressForm: {
    width: [1, 1, 1, 1, 1],
    className: 'address_form',
  },
  contactMap: {
    width: [1, 1, 1, 1, 1],
    className: 'contact_map',
  },
  button: {
    type: 'button',
    fontSize: '18px',
    fontWeight: 'bold',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '55px',
    width: '60%',
    display: 'block',
    margin: '0 auto',
  },
  note: {
    fontSize: ['13px', '14px', '15px', '15px', '15px'],
    color: '#5d646d',
    lineHeight: '1.5',
    textAlign: 'center',
    mt: '10px',
    mb: '0',
    pl: '20px',
    pr: '20px',
  },
};

export default ContactSection;
