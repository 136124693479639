import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../containers/Hosting/Navbar';
import BannerSectionOther from '../containers/Hosting/Banner/BannerOther/Contact';
import InfoOtherSection from '../containers/Hosting/InfoOther/Contact';
import Footer from '../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../components/seo';
import TopRibbon from '../containers/Hosting/TopRibbon';
export default () => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Committed to making AI work for you"
          description="Get in touch with Rapid Acceleration Partners. We are happy to help you anytime. Know more about RAPFlow and RAPBot for automating your business."
        />

        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="ContactPage">
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSectionOther />
          <InfoOtherSection />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
